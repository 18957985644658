import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/LiangHua',
    name: 'LiangHua',
    component: () => import('@/views/Lianghua/index.vue')
  },

  {
    path: '/grade',
    name: 'grade',
    component: () => import('@/views/grade/index.vue')
  },
  {
    path: '/other',
    name: 'other',
    component: () => import('@/views/other/index.vue')
  },
  {
    path: '/green',
    name: 'green',
    component: () => import('@/views/green/index.vue')
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import('@/views/integration/index.vue')
  },
  {
    path: '/SmartPark',
    name: 'SmartPark',
    component: () => import('@/views/SmartPark/index.vue')
  },
  {
    path: '/wisdom',
    name: 'wisdom',
    component: () => import('@/views/wisdom/index.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.screenY = 0
  next()
})

export default router



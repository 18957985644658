<template>
  <div class="title">
    <p class="eg-name" v-if="lang == 'zh'">{{ egName }}</p>
    <p class="right-title">
      <span class="title-name">{{ titleName }}</span>
      <span class="title-content" :style="style">{{ titleCon }}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "contentTitle",
};
</script>
<script setup name=''>
import { computed } from "vue";
import { useStore } from "vuex";
defineProps({
  egName: {
    type: String,
    default: "",
  },
  titleName: {
    type: String,
    default: "",
  },
  titleCon: {
    type: String,
    default: "",
  },
  float: {
    type: String,
    default: "none",
  },
  style: {
    type: Object,
    default: {},
  },
});
const store = useStore();

const lang = computed(() => {
  return store.state.lang;
});
</script>

<style scoped lang="less">
// .title-content {
//   color: #666666;
// }

@media screen and (max-width: 992px) {
  .title {
    margin-bottom: 40px;
  }

  .eg-name {
    font-size: 2.667vw;
  }

  .title-name {
    font-size: 7.467vw;
    font-weight: bold;
    padding: 2.667vw 0;
  }

  .title-content {
    display: block;
    color: #666666;
    font-size: 12px;
  }

  p:nth-child(2) {
    padding-top: 10px;
  }
}

@media screen and (min-width: 993px) {
  .title {
    display: flex;
    align-items: inherit;
    white-space: break-spaces;
    margin-bottom: 100px;

    .eg-name {
      width: 171px;
      margin-right: 80px;
    }

    .title-name {
      font-size: 56px;
      margin: 0 50px 0 0;
      font-weight: bold;
    }

    .title-content {
      display: inline-block;
    }
    .right-title {
      width: 100%;
    }
  }
}
</style>
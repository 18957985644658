<template>
  <router-view />
</template>

<style lang="less">
@media screen and (max-width: 992px) {
  .el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .el-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>

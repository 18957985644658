<template>
  <div class="height-wrap">
    <div class="header" ref="header" @mouseleave="leave">
      <div class="pc-wrap">
        <div class="left-wrap">
          <div class="left">
            <router-link to="/">
              <img
                class="mobile-img"
                :src="require('../assets/images/logo.png')"
                alt=""
              />
              <img
                class="pc-img"
                :src="require('@/assets/images/black-logo.png')"
                alt=""
              />
            </router-link>
          </div>
          <div class="right">
            <ul class="r-title">
              <li class="r-li" v-for="(item, index) in headerList" :key="index">
                <p class="one-t" @click="goto('/', item.id, index, item.name)">
                  <span>{{ $t(item.lang) }}</span>
                  <img
                    class="sj"
                    :src="require('../assets/images/hsj.png')"
                    alt=""
                    v-if="item.item"
                  />
                </p>
                <ul class="item" v-if="item.item" v-show="show">
                  <li
                    class="item-li"
                    v-for="(Citem, Cindex) in item.item"
                    :key="Cindex"
                  >
                    <router-link :to="Citem.url" v-if="Citem.url">
                      <p>{{ $t(Citem.lang) }}</p>
                    </router-link>
                    <p v-else>{{ $t(Citem.lang) }}</p>
                    <ul class="Citem" v-if="Citem.items">
                      <li v-for="(Sitem, Sindex) in Citem.items" :key="Sindex">
                        <p
                          class="Sname"
                          :class="Sindex == hoverIndex ? 'liHover2' : ''"
                        >
                          {{ $t(Sitem.lang) }}
                        </p>
                        <ul class="Sitem">
                          <li
                            class="Sli"
                            @mouseenter="mouseenter(Sindex)"
                            @mouseleave="hoverIndex = -1"
                            v-for="(list, lIndex) in Sitem.list"
                            :key="lIndex"
                          >
                            <router-link :to="list.url">
                              <p>{{ $t(list.lang) }}</p>
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="item-li">
                    <p>
                      <router-link to="/wisdom">{{
                        $t("global.SmartBuildings")
                      }}</router-link>
                    </p>
                  </li>
                  <li class="item-li">
                    <p>
                      <router-link to="/SmartPark">{{
                        $t("global.SmartParkTitle")
                      }}</router-link>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="pc-logo">
            <img src="@/assets/images/logo2.png" alt="" />
          </div>
        </div>

        <div class="changeLang">
          <p
            @click="changeLang('zh')"
            :class="activeLang == 'zh' ? 'activeLang' : ''"
          >
            zh
          </p>
          <span>/</span>
          <p
            @click="changeLang('en')"
            :class="activeLang == 'en' ? 'activeLang' : ''"
          >
            en
          </p>
        </div>
        <!-- <div class="wisdom wisdom-pc">
          <li class="r-li">
            <router-link to="/wisdom">智慧建筑</router-link>
          </li>
          <li class="r-li">
            <router-link to="/SmartPark">智慧园区</router-link>
          </li>
        </div> -->
      </div>
      <div class="mobile">
        <img
          @click="showLeft = true"
          class="expand"
          src="../assets/images/expand.png"
          alt=""
        />
        <!-- 左侧弹出 -->
        <el-drawer
          size="70%"
          v-model="showLeft"
          direction="rtl"
          :before-close="handleClose"
        >
          <ul>
            <li
              class="item-li"
              v-for="(item, index) in headerList"
              :key="index"
            >
              <p @click="handleNav('/', item.id, index, item.name)">
                {{ $t(item.lang) }}
                <img
                  class="sj"
                  :src="
                    route.path == '/'
                      ? require('../assets/images/sj.png')
                      : require('../assets/images/hsj.png')
                  "
                  alt=""
                  v-if="item.item"
                />
              </p>
              <ul v-if="item.item" class="Citem" v-show="showMobile">
                <li
                  class="Citem-li"
                  v-for="(Citem, Cindex) in item.item"
                  :key="Cindex"
                  :class="Citem.items ? '' : 'hover-Citem-li'"
                >
                  <router-link :to="Citem.url" v-if="Citem.url">
                    <p class="name">
                      {{ $t(Citem.lang) }}
                    </p>
                  </router-link>
                  <p class="name" v-else>
                    {{ $t(Citem.lang) }}
                  </p>
                  <ul class="items" v-if="Citem.items">
                    <li
                      class="items-li"
                      v-for="(items, indexs) in Citem.items"
                      :key="indexs"
                    >
                      <router-link :to="items.url" v-if="items.url">
                        <p>{{ $t(items.lang) }}</p>
                      </router-link>
                      <p v-else>
                        {{ $t(items.lang) }}
                      </p>

                      <ul class="Sitem">
                        <li
                          class="Sitem-li"
                          v-for="(Sitem, Sindex) in items.list"
                          :key="Sindex"
                        >
                          <router-link :to="Sitem.url">
                            <p>{{ $t(Sitem.lang) }}</p>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <div class="wisdom">
              <li class="item-li">
                <router-link to="/wisdom">{{
                  $t("global.SmartBuildings")
                }}</router-link>
              </li>
              <li class="item-li">
                <router-link to="/SmartPark">{{
                  $t("global.SmartParkTitle")
                }}</router-link>
              </li>
            </div>
            <div class="changeLang-mobile">
              <p
                @click="changeLang('zh')"
                :class="activeLang == 'zh' ? 'activeLang' : ''"
              >
                zh
              </p>
              <span>/</span>
              <p
                @click="changeLang('en')"
                :class="activeLang == 'en' ? 'activeLang' : ''"
              >
                en
              </p>
            </div>
          </ul>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
};
</script>
<script setup name='header'>
import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
const headerList = reactive([
  {
    name: "能力领域",
    id: "core",
    lang: "global.CapabilityAreas",
  },
  {
    name: "产品与服务",
    lang: "global.ProductsServices",
    item: [
      {
        name: "智能制造",
        lang: "global.IntelligentManufacturing",
        items: [
          {
            title: "诊断咨询服务",
            lang: "header.DiagnosticConsulting",
            list: [
              {
                name: "两化融合",
                url: "/LiangHua",
                lang: "global.lianghua",
              },
              {
                name: "智能制造成熟度",
                url: "/grade",
                lang: "header.grade",
              },
              {
                name: "工业互联网",
                url: "/other",
                lang: "global.IndustrialInternet",
              },
              {
                name: "绿色工厂",
                url: "/green",
                lang: "global.GreenFactory",
              },
            ],
          },
          {
            title: "集成转型服务",
            lang: "global.IntegrationTransformation",
            list: [
              {
                name: "AI机器视觉识别",
                url: "/integration",
                lang: "header.AI",
              },
              {
                name: "低碳改造",
                url: "/integration",
                lang: "global.LowCarbon",
              },
              {
                name: "数据集成",
                url: "/integration",
                lang: "global.DataIntegration",
              },
              {
                name: "数字孪生",
                url: "/integration",
                lang: "global.DigitalTwin",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "解决方案",
    id: "TotalSolution",
    lang: "header.Solutions",
  },
  {
    name: "关于我们",
    id: "about",
    lang: "header.About",
  },
]);
const isMobile = ref(false);
const showLeft = ref(false);
const showMobile = ref(false);
if (window.innerWidth < 768) {
  //  手机
  isMobile.value = true;
} else {
  //  不是手机
  isMobile.value = false;
}
const show = ref(false);
const route = useRoute();
const router = useRouter();
const goto = (page, selectID, index) => {
  // 如果当前点击的是“产品服务”
  if (index === 1) {
    show.value = !show.value;
    headerAni.value = true;
    if (activeLang.value == "zh") {
      header.value.id = "addHeight";
    } else if (activeLang.value == "en") {
      header.value.id = "addHeight2";
    }
  } else {
    if (page == route.path) {
      //如果当前已经在这个页面不跳转 直接去
      let toElement = document.getElementById(selectID);
      toElement.scrollIntoView(true);
    } else {
      //否则跳转路由
      localStorage.setItem("toId", selectID);
      router.push({ path: page });
    }
  }
};
const handleNav = (page, selectID, index) => {
  // 如果当前点击的是“产品服务”
  if (index === 1) {
    showMobile.value = !showMobile.value;
  } else {
    if (page == route.path) {
      //如果当前已经在这个页面不跳转 直接去
      let toElement = document.getElementById(selectID);
      toElement.scrollIntoView(true);
    } else {
      //否则跳转路由
      localStorage.setItem("toId", selectID);
      router.push({ path: page });
    }
    showMobile.value = false;
  }
};
const handleClose = () => {
  showLeft.value = false;
};
const hoverIndex = ref(-1);
const mouseenter = (Sindex) => {
  hoverIndex.value = Sindex;
};
const header = ref(null);
const headerAni = ref(false);
const hover = (name) => {
  if (name == "产品服务") {
    headerAni.value = true;
  }
  if (name == "产品服务" && activeLang.value == "zh") {
    header.value.id = "addHeight";
  } else if (name == "产品服务" && activeLang.value == "en") {
    header.value.id = "addHeight2";
  }
};

const leave = () => {
  if (headerAni.value && !show.value) {
    header.value.id = "removeHeight";
  }
  headerAni.value = false;
};
const store = useStore();
const activeLang = computed(() => {
  return store.state.lang;
});
// const activeLang = ref(localStorage.getItem('ucWebLang') || 'zh')
const { locale } = useI18n();
const changeLang = (lang) => {
  localStorage.setItem("ucWebLang", lang);
  store.commit("SET_LANG", lang);
  locale.value = lang; // 切换应用的语言
};
</script>

<style scoped lang="less">
@media (max-width: 992px) {
  .right,
  .pc-img,
  .wisdom-pc,
  .pc-logo,
  .changeLang {
    display: none;
  }

  .changeLang-mobile {
    width: 90px;
    padding: 3px 2px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #000;

    p {
      text-align: center;
      width: 45%;
    }

    .activeLang {
      width: 42%;
      background: #fff;
      border-radius: 8px;
      margin: 0 auto;
    }
  }

  .height-wrap {
    width: 100%;
    padding: 0 20px;
    height: 16vw;
    position: fixed;
    top: 0;
    z-index: 9999;
    background: #2e2e2e;

    .header {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wisdom {
        border-top: 1px solid;
        margin-top: 10px;
        padding-top: 10px;
      }

      .left .mobile-img {
        width: 33.333vw;
      }

      /deep/.mobile {
        .expand {
          width: 4.8vw;
          height: 4.533vw;
        }

        .sj {
          width: 2.667vw;
        }

        .el-drawer__header {
          margin-bottom: 0;
          // padding-top: 0;
        }

        .el-drawer__close-btn {
          font-size: 6vw;
          color: #fff;
        }

        .el-drawer {
          background: #3b77df;
          padding: 0 0 0 10px;

          .item-li {
            font-size: 4vw;
            padding: 10px 0;
            font-weight: bold;
          }

          .Citem,
          .items,
          .Sitem {
            padding-left: 2.667vw;
          }

          .items-li {
            padding-top: 10px;
          }

          .hover-Citem-li {
            .name {
              display: inline-block;
              border-bottom: 1px solid #fff;
            }
          }

          .Sitem {
            font-size: 3.4vw;

            .Sitem-li {
              padding: 10px;

              p {
                display: inline-block;
                border-bottom: 1px solid #fff;
              }
            }
          }

          .Citem-li {
            padding-top: 2.667vw;
          }
        }
      }
    }
  }
}

@media (min-width: 993px) {
  .mobile,
  .mobile-img {
    display: none;
  }

  .height-wrap {
    width: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
  }

  .header {
    padding: 0 60px;
    position: relative;
    background: #3b77df;
    width: 100%;
    height: 80px;

    .pc-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      .left-wrap {
        display: flex;
        align-items: center;
        width: calc(100% - 100px);

        .pc-logo {
          width: calc(100% - 698px);
          display: flex;
          justify-content: center;

          img {
            width: 45px;
            height: 45px;
          }
        }
      }

      .changeLang {
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        width: 100px;
        padding: 10px 0;
        border-radius: 10px;
        border: 1px solid #fff;
        font-size: 24px;

        p {
          width: 35%;
          text-align: center;
          cursor: pointer;
        }

        span {
          display: inline-block;
          margin: 0 3px;
        }

        .activeLang {
          background-color: #fff;
          border-radius: 50px;
        }
      }
    }

    .left {
      a {
        height: 50px;
        display: flex;
        align-items: center;
      }

      .pc-img {
        width: 130px;
        height: 33px;
        margin-right: 120px;
      }
    }

    .center {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      img {
        width: 45px;
        height: 45px;
      }
    }

    // .wisdom {
    //   display: flex;
    //   position: absolute;
    //   right: 3.125vw;
    //   font-size: 1.042vw;

    //   li {
    //     margin-right: 0;
    //     // padding: 0.677vw 1.146vw;
    //     height: 2.292vw;
    //     width: 6.25vw;
    //     text-align: center;
    //     line-height: 2.292vw;

    //     &:nth-child(1) {
    //       border-radius: 22px 0px 0px 22px;
    //       border: 1px solid #979797;
    //       margin-right: -1px;
    //     }

    //     &:nth-child(2) {
    //       border-radius: 0px 22px 22px 0px;
    //       border: 1px solid #979797;
    //     }
    //   }
    // }

    .right {
      .r-title {
        display: flex;

        .one-t {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 24px;

          span {
            white-space: nowrap;
          }
        }

        .sj {
          width: 10px;
          height: 6px;
          margin-left: 6px;
        }

        .r-li {
          position: relative;

          &:not(:last-child) {
            margin-right: 60px;
          }

          &:nth-child(1) {
            display: none;
          }
        }
      }

      .item {
        position: absolute;
        z-index: 9;
        width: 220px;

        .item-li {
          margin-top: 20px;

          &:nth-child(2),
          &:nth-child(3) {
            cursor: pointer;
            border-top: 1px solid #2e2e2e;
            padding-top: 10px;
            margin-top: 10px;
          }
        }
      }

      .Citem {
        border-top: 1px solid #2e2e2e;
        color: #31589e;
        padding: 10px 0 0;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;

        .Sname {
          font-size: 14px;
        }

        .Sitem {
          font-size: 13px;

          li {
            // line-height: 30px;
            cursor: pointer;
            padding: 8px 0;

            &:hover {
              color: #000;
              border-bottom: 1px solid #000;
            }
          }
        }
      }
    }
  }

  .liHover2 {
    color: #2e2e2e;
  }

  #addHeight,
  #addHeight2 {
    color: #2e2e2e;
    background: #3b77df;
  }

  #removeHeight,
  #removeHeight2 {
    background: #3b77df;
  }

  #addHeight {
    animation: example 0.5s linear forwards;
  }

  #removeHeight {
    animation: level 0.5s linear forwards;
  }

  @keyframes level {
    from {
      height: 380px;
    }

    to {
      height: 80px;
    }
  }

  @keyframes example {
    from {
      height: 80px;
    }

    to {
      height: 380px;
    }
  }

  #addHeight2 {
    animation: example2 0.5s linear forwards;
  }

  @keyframes example2 {
    from {
      height: 80px;
    }

    to {
      height: 500px;
    }
  }

  @media (min-width: 993px) and (max-width: 1090px) {
    .r-li:not(:last-child) {
      margin-right: 30px !important;
    }

    .left-wrap {
      .pc-img {
        margin-right: 70px !important;
      }
    }
  }
}
</style>
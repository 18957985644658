<template>
  <div class="HeightCom" :style="{ '--height': innerHeight + 'px' }">
    <slot />
  </div>
</template>
<script>
export default {
  name: "HeightCom",
};
</script>
<script setup name='HeightCom'>
import { ref } from "vue";
const innerHeight = ref(window.innerHeight);
</script>

<style scoped lang="less">
@media (max-width: 992px) {
  .HeightCom {
    min-height: 100% !important;
  }
}

@media (min-width: 993px) {
  .HeightCom {
    min-height: var(--height);
    height: 100%;
  }
}
</style>